





























import { Blacklist } from "@/models/blacklist.model";
import Vue from "vue";
import Component from "vue-class-component";
import Vuetable from "vuetable-2";
import { namespace } from "vuex-class";

const blacklistNamespace = namespace("Blacklist");

@Component({ components: { Vuetable } })
export default class BlacklistView extends Vue {
  fields = [
    { name: "email", sortField: "Email" },
    { name: "created_at", sortField: "Created at" },
    { name: "actions", sortField: "Actions" },
  ];
  isLoading = false;

  @blacklistNamespace.Getter("all")
  blacklist!: Blacklist[];

  @blacklistNamespace.Action("fetchAll")
  fetchAll!: () => Promise<Blacklist[]>;

  @blacklistNamespace.Action("delete")
  delete!: (id: string) => Promise<Blacklist>;

  get locale() {
    return this.$root.$i18n.locale;
  }

  async deleteBlacklist(blacklist: Blacklist) {
    this.isLoading = true;
    const isConfirmed = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to remove ${blacklist.email}?`,
      {
        title: "Confirm delete",
        okVariant: "danger",
        centered: true,
      }
    );
    if (isConfirmed) {
      await this.delete(blacklist.id);
    }
    this.isLoading = false;
    this.doFetchAll();
  }

  async doFetchAll() {
    this.isLoading = true;
    await this.fetchAll();
    this.isLoading = false;
  }

  async created() {
    await this.doFetchAll();
  }
}
